import * as React from "react";
import PrototypeContent from "../components/PrototypeContent/PrototypeContent";

const PrototypePage = () => {
  return (
    <>
      <PrototypeContent />
    </>
  );
};

export default PrototypePage;
