import { Link } from "@reach/router";
import * as React from "react";
import "./PrototypeContainer.scss";
const PrototypeContent = () => {
  return (
    <div className="prototype-wrapper">
      <div className="prototype-container">
        <div>
          <iframe
            title="Qavant Prototype"
            style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
            width="400"
            height="700"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FsgzmSce7CRPY4bBTr94Vo8%2FQavant-app-(INTERNO-UX)%3Fpage-id%3D0%253A1%26node-id%3D3292%253A19375%26viewport%3D-457%252C-256%252C0.08%26scaling%3Dscale-down%26starting-point-node-id%3D3292%253A21039"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="fixed-button-demo">
        <Link to="/#contact" offset={50}>
          <button type="button">Comunicate con nosotros</button>
        </Link>
      </div>
    </div>
  );
};

export default PrototypeContent;
